<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :details="details"
      :metadata="metadata"
      translatePrefix="application.pages"
    />
  </div>
</template>

<script>
import {
  CargillCrudMetaView,
  responseToOptionsInContext,
  helpers
} from '@cargill/shared'
import service from '../api/inventoryService'
import qualitySpecificationService from '../api/inventoryQualitySpecificationService'
import impuritySpecificationService from '../api/inventoryImpuritySpecificationService'
import _ from 'lodash'
import { CrudAction } from '@brain/core'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {},
      actionsToUpdateOptions: [
        CrudAction.DELETE,
        CrudAction.IMPORT,
        CrudAction.CREATE,
        CrudAction.UPDATE
      ],
      ctx: null
    }
  },
  methods: {
    registerCrudProcessing() {
      for (const action of this.actionsToUpdateOptions) {
        service.registerPostProcessing(action, this.updateInventoryOptions)
      }
    },
    cancelCrudProcessing() {
      for (const action of this.actionsToUpdateOptions) {
        service.cancelPostProcessing(action, this.updateInventoryOptions)
      }
    },
    async updateInventoryOptions() {
      const ctx = await service.getValidationContext()
      responseToOptionsInContext(ctx)
      this.ctx = ctx
    },
    async getQualityMeta() {
      const qualityMeta = await qualitySpecificationService.getMeta()
      qualityMeta.name = 'inventoryQualitySpecification'
      qualityMeta.masterName = 'inventoryId'
      qualityMeta.fields = qualityMeta.fields.filter(
        (field) => field.id != 'inventory'
      )

      qualityMeta.detailsObject = {
        service: qualitySpecificationService
      }
      return qualityMeta
    },
    async getImpurityMeta() {
      const impuritySpecificationMeta =
        await impuritySpecificationService.getMeta()
      impuritySpecificationMeta.name = 'inventoryImpuritySpecification'
      impuritySpecificationMeta.masterName = 'inventoryId'
      impuritySpecificationMeta.fields =
        impuritySpecificationMeta.fields.filter(
          (field) => field.id != 'inventory'
        )
      impuritySpecificationMeta.detailsObject = {
        service: impuritySpecificationService
      }
      return impuritySpecificationMeta
    },
    async getMetaDetails() {
      return await Promise.all([this.getQualityMeta(), this.getImpurityMeta()])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      const ctx = await service.getValidationContext()
      responseToOptionsInContext(ctx)
      this.ctx = ctx
      this.fieldsById = _.keyBy(meta.fields, (field) => field.id)
      this.fieldsById.purchaseContract.options = async ({ itemData }) => {
        const contracts = this.ctx.purchaseContractOptions ?? []
        if (itemData?.id) {
          const currentInventory = await service.getById(itemData?.id)
          const currentContract = currentInventory.purchaseContract
          currentContract.text = currentContract.label
          return [currentContract, ...contracts]
        } else {
          return contracts
        }
      }
      this.fieldsById.warehouse.options = ctx.warehouseOptions
      this.fieldsById.material.options = ctx.materialOptions
      this.fieldsById.weightUnit.options = ctx.weightUnitOptions
      this.fieldsById.contractPricePointsUnit.options =
        ctx.contractPricePointsUnitOptions
      this.fieldsById.counterPartySector.options = ctx.counterPartySectorOptions
      this.fieldsById.marketType.options = helpers.createEnumOptions(
        this.fieldsById.marketType,
        this.fieldsById.marketType.options,
        (field, value) =>
          this.$t(`application.enums.${field.enumType}.${value}`)
      )
      this.fieldsById.marketType.disabled = true
      //  this.fieldsById.marketType.onValueChange = (value, fields) => {
      //    fields.marketType.disabled = true
      //  }
      this.fieldsById.counterPartySector.disabled = true
      //  this.fieldsById.counterPartySector.onValueChange = (value, fields) => {
      //   fields.counterPartySector.disabled = true
      // }
      // this.fieldsById.originState.disabled = true
      //this.fieldsById.originState.onValueChange = (value, fields) => {
      //  fields.originState.disabled = true
      //}

      helpers.updateOptionRefByKey(
        this.fieldsById.marketType.options,
        ctx.marketTypeByPurchaseContract
      )
      helpers.updateOptionRefByKey(
        ctx.counterPartySectorOptions,
        ctx.counterPartySectorByPurchaseContract
      )
      this.fieldsById.purchaseContract.onValueChange = (
        purchaseContract,
        fields
      ) => {
        fields.marketType.value =
          ctx.marketTypeByPurchaseContract[purchaseContract?.value]
        fields.counterPartySector.value =
          ctx.counterPartySectorByPurchaseContract[purchaseContract?.value]
        fields.marketType.disabled = true
        fields.counterPartySector.disabled = true
      }

      //  this.fieldsById.warehouse.onValueChange = (warehouse, fields) => {
      //  fields.originState.value = ctx.stateByWarehouse[warehouse?.value]
      //  }
      meta.details = metaDetails
      return meta
    }
  },
  beforeDestroy() {
    this.cancelCrudProcessing()
  },
  created() {
    this.registerCrudProcessing()
    this.getMeta().then((meta) => {
      meta.details.forEach((detail) => {
        this.details[detail.name] = detail.detailsObject
      })
      this.metadata = meta
    })
  }
}
</script>
